import React, { useState } from 'react';
import Campaigns from './Campaigns';
import TargetingGroups from './TargetingGroups';
import Creatives from './Creatives';

const Dashboard = ({ clientID }) => {
  const [activeTab, setActiveTab] = useState("Campaign");

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>

      {/* Tab Buttons */}
      <div className="tabs">
        <button className={activeTab === "Campaign" ? "active" : ""} onClick={() => setActiveTab("Campaign")}>
          Campaign
        </button>
        <button className={activeTab === "TargetingGroup" ? "active" : ""} onClick={() => setActiveTab("TargetingGroup")}>
          Targeting Group
        </button>
        <button className={activeTab === "Creative" ? "active" : ""} onClick={() => setActiveTab("Creative")}>
          Creative
        </button>
      </div>

      {/* Render Active Tab */}
      {activeTab === "Campaign" && <Campaigns clientID={clientID} />}
      {activeTab === "TargetingGroup" && <TargetingGroups clientID={clientID} />}
      {activeTab === "Creative" && <Creatives clientID={clientID} />}
    </div>
  );
};

export default Dashboard;
